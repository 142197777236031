import React, { useState } from "react";
import { PageProps } from "gatsby";
import Layout from "../components/layout";
import { uniqueId, isEmpty, range } from "lodash";

export default function FormtestPage(props: PageProps) {
  const [formData, setFormData] = useState({
    materialTitles: "",
  });

  const titleChangeHandler = (e: any) => {
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    let newFormData = { ...formData };
    newFormData[name] = value;

    console.log(newFormData);

    setFormData(newFormData);

    // setFormData(e.target.value)
  };

  return (
    <>
      <Layout>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12 p-5">
            <form action="">
              {/* <input type="text" name="titleValue" value={formData.titleValue} onChange={titleChangeHandler} /> */}

              <input
                value={formData.materialTitles}
                name="materialTitles" //{inputName}
                type="text"
                id="materialTitles"
                key="materialTitles"
                onChange={titleChangeHandler}
                className="w-full css-1s2u09g-control px-2"
              />
            </form>
          </div>
        </div>
      </Layout>
    </>
  );
}
